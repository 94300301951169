<template>
  <div>
    <multiselect
      class="transition duration-300"
      v-model="internalValue"
      :options="options"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="true"
      label="name"
      track-by="name"
      :preselect-first="true"
      style="width: 477px; height: 35px"
      :deselect-label="''"
      :select-label="''"
      :placeholder="'Тип работ '"
      :searchable="true"
      :open-direction="'bottom'"
      @close="isObjectShowen = !isObjectShowen"
      @open="isObjectShowen = !isObjectShowen"
      :taggable="true"
      @tag="newTag"
      tag-placeholder="Добавить"
    >
      <svg
        slot="caret"
        class="duration-300 arrow my-4 mx-3 p-0"
        :style="[
          isObjectShowen
            ? { transform: 'rotateZ(-180deg)' }
            : { transform: 'rotateZ(0deg)' },
        ]"
        width="14"
        height="8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.266 0L7 4.823 1.734 0 0 1.589 7 8l7-6.411L12.266 0z"
          fill="#5477A9"
        ></path>
        <transition
          enter-active-class="duration-300 ease-in"
          leave-active-class="duration-300"
          enter-to-class="overflow-hidden max-h-320"
          leave-class="overflow-hidden max-h-320"
          leave-to-class="overflow-hidden max-h-0"
          enter-class="overflow-hidden max-h-0"
        />
      </svg>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect,
  },
  props: ['value', 'options', 'tag'],
  data() {
    return {
      internalValue: this.value,
      isObjectShowen: false,
    }
  },
  watch: {
    internalValue(v) {
      this.$emit('input', v)
    },
  },
  methods: {
    newTag(newtag) {
      const tag = {
        name: newtag,
      }
      this.options.push(tag)
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->

<style>
.multiselect__single {
  color: #677785;
  font-weight: bold;
  font-size: 13px;
}
.multiselect__placeholder {
  display: inline-block !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.multiselect__option--highlight {
  background: #eef5ff !important;
  color: #677785;
}

.multiselect__option--selected {
  color: #333333;
  background-color: white;
}
.multiselect__option--highlight:hover {
  color: #333333;
}
.multiselect__option--selected:after {
  content: '✓';
  color: #5477a9;
  font-weight: bold;
  font-size: 13px;
}
.multiselect__tag {
  font-family: Lato;
  font-style: normal;

  font-size: 13px;
  line-height: 14px;
  color: #677785;
  background-color: #991515;
  border-radius: 10px;
  margin-bottom: 0px;
}
.multiselect__tag-icon::after {
  color: #91b4e7;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: #5477a9;
  border-radius: 50%;
}
.multiselect__tag-icon {
  transition: 0.2s;
}
.multiselect__tags {
  min-height: 35px;

  border: 1px solid #d1dbe4;
}
.arrow {
  position: absolute;
  right: 0;
  color: red;
}
.multiselect__content-wrapper,
.multiselect-leave-active {
  transition: 0.5s;
  margin-top: 5px;
  border-top: 1px solid rgba(223, 229, 235, 0.8);
  border-radius: 2px;
}
.multiselect__element {
  font-size: 13px;
}
</style>
