<template lang="pug">
.col-start-1.col-end-3.my-4.overflow-y-auto.overflow-x-hidden
  table.w-full.table-fixed
    thead.text-left
      tr.border-b.border-reallylightblue.h-16
        th.bg-white.top-0.sticky.z-10
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold(
            v-if='!isNewTrailer'
          ) {{ $t("objects_tabs.trailer") }}
        th.bg-white.top-0.sticky.z-10.truncate.w-36
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold(
            v-if='!isNewTrailer'
          ) {{ $t("objects_tabs.drivers.from") }}
        th.bg-white.top-0.sticky.z-10.w-36
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold(
            v-if='!isNewTrailer'
          ) {{ $t("objects_tabs.drivers.to") }}
        th.w-6.text-left.top-0.sticky.z-10.bg-white
          svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-all.ease-in-out.duration-200.transform(
            :class='isNewTrailer ? "rotate-45" : "rotate-0"',
            width='16',
            height='16',
            fill='none',
            xmlns='http://www.w3.org/2000/svg',
            @click='createTrailer'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z'
            )

    tbody(v-if='!isNewTrailer')
      tr.cursor-pointer.hover_bg-hoverrow(
        v-for='trailer in filterDataTrailers',
        :key='trailer.id',
        @click='handlTrailer(trailer)'
      )
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ trailer.trailer.name }}
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ trailer.date_from }}
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ trailer.date_to }}
        td.flex.items-center.justify-center
          svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
            width='12',
            height='16',
            fill='none',
            xmlns='http://www.w3.org/2000/svg',
            @click='(event) => { event.stopPropagation(); deleteTrailer(trailer.id); }'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z'
            )

    tbody(v-else)
      tr.border-b.border-reallylightblue
        th.sticky.bg-white.z-10(:colspan='4')
          .flex.items-end.my-3
            .flex.flex-col.unitDriverModal.text-left(class='w-2/3')
              .flex.flex-col.relative
                span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.trailer") }}
                el-select.mt-3(
                  :disabled='!notRole && role !== "EDITOR"',
                  filterable,
                  v-model='unitTrailer.trailer.id',
                  :class='{ "input-error": isTrailerNameMissing }'
                )
                  el-option(
                    v-for='item in trailers',
                    :key='item.id',
                    :label='item.name',
                    :value='item.id'
                  )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isTrailerNameMissing'
                  ) {{ $t("objects.name") }}
              span.mt-3.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.drivers.from") }}
              el-date-picker.my-3(
                :clearable='false',
                :picker-options='pickerOptions',
                popper-class='reportsDatepicker',
                type='datetime',
                v-model='unitTrailer.date_from',
                :format='dateTimeFormat',
                value-format='yyyy-MM-dd HH:mm:ss'
              )
              span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.drivers.to") }}
              el-date-picker.mt-3(
                :clearable='false',
                :picker-options='pickerOptions',
                popper-class='reportsDatepicker',
                type='datetime',
                v-model='unitTrailer.date_to',
                :format='dateTimeFormat',
                value-format='yyyy-MM-dd HH:mm:ss'
              )
          .flex.flex-col.mt-4.col-start-1.col-end-3.text-left
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold Тип работ
            //- el-input(type='text', class='w-2/3', v-model='unitTrailer.work_type')
            skifMultiSelectOneValue.multiSelectUnits(
              :options='multiSelectOptions',
              v-model='multipleSelection',
              @tag='addMultiSelectValue'
            )
          .flex.flex-col.mt-4.col-start-1.col-end-3.text-left
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold Мин. скорость в км/ч
            el-input(type='number', class='w-2/3', v-model='unitTrailer.min_speed')
          .flex.flex-col.mt-4.col-start-1.col-end-3.text-left
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold Макс. скорость в км/ч
            el-input(type='number', class='w-2/3', v-model='unitTrailer.max_speed')
          .flex.flex-col.mt-4.col-start-1.col-end-3.text-left
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold Маска названия датчиков
            el-input(class='w-2/3', v-model='unitTrailer.sensor_name_mask')
          .flex.justify-end.mt-2.mb-2(class='w-3/3')
            skif-button(width='28', @click='createOrUpdateTrailer') {{ $t("btn.save") }}
</template>
<script>
// import unitTrailer from '../mixins/unittrailers'
import skifMultiSelectOneValue from '@/utils/skifMultiSelectOneValue'
import { unitTrailersApi } from '@/api'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    skifMultiSelectOneValue,
  },
  props: ['unit', 'role', 'pickerOptions', 'dateTimeFormat'],
  // mixins: [unitTrailer],
  data() {
    return {
      multiSelectOptions: [
        { name: 'Тип работ 1' },
        { name: 'Тип работ 2' },
        { name: 'Тип работ 3' },
      ],
      multipleSelection: [],

      filterStringTrailers: '',
      unitTrailers: [],
      isNewTrailer: false,
      isTrailerNameMissing: false,
      unitTrailer: {
        trailer: { id: '' },
        unit: { id: '' },
        date_from: '',
        date_to: '',
      },
      fullTr: '',
    }
  },

  watch: {
    'unitTrailer.trailer.id': function (val) {
      if (val !== '') {
        this.isTrailerNameMissing = false
      }
    },
  },
  computed: {
    ...mapGetters('login', ['notRole']),
    ...mapGetters('trailers', ['trailerById']),
    ...mapState('trailers', {
      trailers: (state) => state.trailers,
    }),
    filterDataTrailers() {
      return this.unitTrailers.filter(
        (trailer) =>
          JSON.stringify(trailer)
            .toLowerCase()
            .indexOf(this.filterStringTrailers.toLowerCase()) !== -1
      )
    },
  },
  methods: {
    addMultiSelectValue(va) {
      this.multiSelectOptions.push(va)
    },
    handlTrailer(val) {
      this.unitTrailer = JSON.parse(JSON.stringify(val))
      this.isNewTrailer = true
      this.$emit('multiplselection', { name: val.work_type })
      this.unitTrailer.work_type = { name: val.work_type }
    },
    isTrailerValid() {
      let error = true
      if (!this.unitTrailer.trailer.id) {
        this.isTrailerNameMissing = this.unitTrailer.trailer.id === ''
        error = false
      }
      return error
    },
    createOrUpdateTrailer() {
      this.unitTrailer.work_type = this.multipleSelection.name
      if (this.isTrailerValid()) {
        if (this.unitTrailer.company_id) {
          unitTrailersApi.updateUnitTrailer(
            this.unitTrailer,
            () => {
              this.getUnitTrailers()
              this.isNewTrailer = false
            },
            (error) => {
              this.$showError(error.response.data.message)
            }
          )
        } else {
          unitTrailersApi.createUnitTrailer(
            this.unitTrailer,
            () => {
              this.getUnitTrailers()
              this.isNewTrailer = false
            },
            (error) => {
              this.$showError(error.response.data.message)
            }
          )
        }
      }
    },
    createTrailer() {
      this.isNewTrailer = !this.isNewTrailer
      this.unitTrailer = {
        trailer: { id: '' },
        unit: { id: this.unit.id },
        date_from: this.from,
        date_to: this.to,
      }
    },
    deleteTrailer(unitTrailerId) {
      unitTrailersApi.deleteUnitTrailer(
        unitTrailerId,
        () => {
          if (this.unitTrailer.id === unitTrailerId) {
            this.isNewTrailer = false
            this.unitTrailer = {
              trailer: { id: '' },
              unit: { id: this.unit.id },
              date_from: this.from,
              date_to: this.to,
            }
          }
          this.getUnitTrailers()
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },
    getUnitTrailers() {
      unitTrailersApi.getUnitTrailers(
        this.unit.id,
        (response) => {
          this.unitTrailers = response.data.list.map((unitTrailer) => {
            const trailer = this.trailerById(unitTrailer.trailer.id)

            return {
              ...unitTrailer,
              trailer: trailer,
            }
          })
          this.isNewTrailer = false
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },
  },
  created() {
    this.getUnitTrailers()
  },
}
</script>
